import type { Address } from '~/types'

type Options = {
  street?: boolean
  postcode?: boolean
  city?: boolean
  country?: boolean
}

export default (address?: Address | null, hide?: Options) => {
  if (!address || (!address.street && !address.postcode && !address.city)) {
    return undefined
  }

  const addressParts: string[] = []
  if (address.street && !hide?.street) {
    addressParts.push(address.street)
  }

  if (address.postcode && address.city && (!hide?.postcode && !hide?.city)) {
    addressParts.push(`${address.postcode} ${address.city}`)
  } else if (address.postcode && !hide?.postcode) {
    addressParts.push(address.postcode)
  } else if (address.city && !hide?.city) {
    addressParts.push(address.city)
  }

  if (address.country && (address.country !== 'Deutschland' && !hide?.country)) {
    addressParts.push(address.country)
  }

  return addressParts.join(', ')
}
